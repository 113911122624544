import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { Button, Card, CardBody, CardHeader, Col, FormGroup, Input, Row } from "reactstrap";
import swal from "sweetalert";
import { editPayment } from "../../services/Services";
import FormValidator from "../../store/reducers/FormValidator";
import ContentWrapper from "../Layout/ContentWrapper"

const EditPayment = ({ payment, closeEvent }) => {

    useEffect(() => {
        console.log(payment);
        setPaymentForm({
            inscription: {
                price: payment.price
            }
        })
    }, [])

    const [paymentForm, setPaymentForm] = useState({
        inscription: {
            price: ''
        }
    });

    const notify = (title) => {
        toast(title, {
            type: "success",
            position: "bottom-center",
        });
    };

    const submitPayment = async (e) => {
        e.preventDefault();

        const form = e.target;
        const inputs = [...form.elements].filter((i) => {
            if (i.name) {
                return ["INPUT", "SELECT"].includes(i.nodeName)
            }
        });
        const { errors } = FormValidator.bulkValidate(inputs);

        setPaymentForm({
            inscription: {
                ...paymentForm.inscription,
            },
            errors,
        });

        //Validate if is valid make api request
        await editPayment(paymentForm, payment.id)
            .then(async (response) => {
                //EVENTO CREADO CORRECTAMENTE
                notify("Pago actualizado.");
                closeEvent();
                setPaymentForm({
                    inscription: {
                        price: ''
                    },
                    errors: {},
                });
            })
            .catch((error) => {
                swal({
                    title: "¡Alerta!",
                    icon: "warning",
                    text: error.data,
                });
            });
    };

    const validateOnChange = (event) => {
        const input = event.target;
        const value = input.type === "checkbox" ? input.checked : input.value;
        const result = FormValidator.validate(input);

        setPaymentForm({
            inscription: {
                ...paymentForm.inscription,
                [input.name]: value,
            },
            errors: {
                ...paymentForm.errors,
                [input.name]: result,
            },
        });
    };

    const hasErrors = (inputName, method) => {
        return (
            paymentForm &&
            paymentForm.errors &&
            paymentForm.errors[inputName] &&
            paymentForm.errors[inputName][method]
        );
    };

    return (
        <ContentWrapper>
            <div className="content-heading">
                <div>
                    Editar pago
                </div>
            </div>
            <Row>
                <Col xs={12} className="text-left">
                    <form
                        className="form-horizontal"
                        name="event"
                        onSubmit={submitPayment}
                    >
                        <Row>
                            <Col xl={12}>
                                <FormGroup row>
                                    <label className="col-md-4 col-form-label">Precio</label>
                                    <div className="col-md-8">
                                        <Input
                                            onChange={validateOnChange}
                                            type="text"
                                            name="price"
                                            invalid={hasErrors("price", "required")}
                                            data-validate='["required"]'
                                            value={paymentForm.inscription.price}
                                        />
                                        {hasErrors("price", "required") && (
                                            <span className="invalid-feedback">
                                                Campo requerido.
                                            </span>
                                        )}
                                    </div>
                                </FormGroup>
                            </Col>
                        </Row>

                        <hr />
                        <Row>
                            <Col xl={12} className="d-flex flex-row justify-content-end">
                                <Button type="submit" color="primary" className="shadow">
                                    Guardar
                                </Button>
                            </Col>
                        </Row>
                    </form>
                </Col>
            </Row>
        </ContentWrapper>
    )
}

export default EditPayment;