import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import ReactDataGrid from "react-data-grid";
import { Container, Button } from "reactstrap";
import ContentWrapper from '../Layout/ContentWrapper';
import { FILES_ENDPOINT, getRequests, updateRequest } from '../../services/Services';
import Swal from "sweetalert2";
import { toast } from "react-toastify";

const PartnerRequests = props => {

    useEffect(() => {
        async function getRequestsAPI() {
            await getRequests().then((result) => {
                setData(result.data);
            });
        }
        getRequestsAPI();
    }, []);

    const [data, setData] = useState([]);

    const rowGetter = (i) => data[i]

    const notify = (title) => {
        toast(title, {
            type: "success",
            position: "bottom-center",
        });
    };

    const ImageFormatter = props => (
        <div className="text-center py-2">
            <img src={`${FILES_ENDPOINT}${props.value}`} className="img-fluid thumb32" alt="avatar" />
        </div>
    );

    const acceptButton = props => (
        <div className="text-center py-2">
            <Button color="warning" onClick={() => acceptPartnerFunction(props)}> <i className="far fa-edit"></i> </Button>
        </div>
    )

    const acceptPartnerFunction = ({ value }) => {
        Swal.fire({
            title: "Deseas aceptar la solicitud de socio?",
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: 'Aceptar',
            denyButtonText: `Rechazar`,
            confirmButtonColor: '#27c24c',
            denyButtonColor: '#ff902b'
        })
            .then(async (result) => {
                if (result.isConfirmed) {
                    await updateRequest(value, { user: { accepter_partner: true, rol: "4" } }).then(() => {
                        setData(data.filter((partner) => partner.id !== value));
                        notify("Solicitud aceptada.");
                    }).catch((error) => {
                        Swal(error.data, {
                            icon: "warning",
                        });
                    })
                } else if (result.isDenied) {
                    await updateRequest(value, { users: { accepter_partner: false, rol: null } }).then(() => {
                        setData(data.filter((partner) => partner.id !== value));
                        notify("Solicitud rechazada.");
                    }).catch((error) => {
                        Swal(error.data, {
                            icon: "warning",
                        });
                    })
                }
            });
    }

    const columns = [
        { key: 'avatar', name: 'Avatar', formatter: ImageFormatter, width: 80 },
        { key: 'company', name: 'Compañía' },
        { key: 'name', name: 'Nombre' },
        { key: 'lastname', name: 'Apellido' },
        { key: 'station_num', name: 'Estación' },
        { key: 'phone_num', name: 'Telefono' },
        { key: 'state', name: 'Estado' },
        { key: 'city', name: 'Ciudad' },
        
        { key: 'id', name: 'Editar', formatter: acceptButton, width: 80 }
    ];

    return (
        <ContentWrapper>
            <div className="content-heading">
                <div className="row">
                    <div className="col-md-9">Solicitudes</div>
                </div>
            </div>
            <Container fluid className="shadow">
                <ReactDataGrid
                    columns={columns}
                    rowGetter={rowGetter}
                    rowsCount={data.length}
                    rowHeight={50}
                    minHeight={700} />
            </Container>
        </ContentWrapper>
    )
}

export default PartnerRequests;