import React, { useState, useEffect } from 'react';
import ReactDataGrid from 'react-data-grid';
import { toast } from 'react-toastify';
import { Button, Container } from 'reactstrap';
import Swal from 'sweetalert';
import { deleteNew, getNews } from '../../services/Services';
import ContentWrapper from '../Layout/ContentWrapper';

const News = props => {

    useEffect(() => {
        async function getNewsAPI() {
            await getNews().then((result) => {
                setData(result.data);
            }).catch((error) => {

            });
        }

        getNewsAPI();
    }, [])

    const [data, setData] = useState([]);

    const notify = (title) => {
        toast(title, {
            type: "success",
            position: "bottom-center",
        });
    };

    const editButton = props => (
        <div className="text-center py-2">
            <Button color="warning" onClick={() => editEvent(props)}> <i className="far fa-edit"></i> </Button>
        </div>
    )

    const deleteButton = props => (
        <div className="text-center py-2">
            <Button color="danger" onClick={() => deleteEventFunction(props)}> <i class="far fa-trash-alt"></i> </Button>
        </div>
    )

    const editEvent = ({ value }) => {
        props.history.push(`/news/${value}`)
    }

    const deleteEventFunction = async ({ value }) => {
        Swal({
            title: "Deseas eliminar la noticia?",
            text: "Una vez eliminada la noticia, no se puede recuperar la información.",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then(async (willDelete) => {
                if (willDelete) {
                    await deleteNew(value).then(() => {
                        let news = [...data];
                        setData(news.filter((nw) => nw.id !== value));
                        notify("Noticia borrada.");
                    }).catch((error) => {
                        Swal(error.data, {
                            icon: "warning",
                        });
                    })
                }
            });
    }

    const rowGetter = (i) => data[i]

    const columns = [
        { key: 'title', name: 'Título' },
        { key: 'id', name: 'Editar', formatter: editButton, width: 80 },
        { key: 'id', name: 'Eliminar', formatter: deleteButton, width: 80 }
    ];

    return (
        <ContentWrapper>
            <div className="content-heading">
                <div className="row">
                    <div className="col-md-9">Noticias</div>
                    <div className="text-right col-md-3">
                        <Button color="primary" className="shadow rounded-pill" onClick={() => props.history.push('/news/new')}> <i className="fas fa-plus"></i> Crear Noticia</Button>
                    </div>
                </div>
            </div>

            <Container fluid className="shadow">
                <ReactDataGrid
                    columns={columns}
                    rowGetter={rowGetter}
                    rowsCount={data.length}
                    rowHeight={50}
                    minHeight={700} />
            </Container>
        </ContentWrapper>
    )
}

export default News;