const Menu = [
    {
        heading: 'Navegación',
        translate: 'sidebar.heading.HEADER'
    },
    {
        name: 'Dashboard',
        path: '/',
        icon: 'fas fa-user-tie'
    },
    {
        name: 'Eventos',
        icon: 'fas fa-calendar-alt',
        submenu: [
            {
                name: 'Eventos',
                path: '/events'
            },
            {
                name: 'Documentos',
                path: '/files'
            },
        ]
    },
    {
        name: 'Expositores',
        path: '/speakers',
        icon: 'fas fa-chalkboard-teacher'
    },

    {
        name: 'Exposiciones',
        path: '/expositions',
        icon: 'fas fa-chalkboard-teacher'
    },
    {
        name: 'Resoluciones',
        path: '/resolutions',
        icon: 'fas fa-check'
    },
    {
        name: 'Patrocinadores',
        path: '/sponsors',
        icon: 'fas fa-building'
    },
    {
        name: 'Clientes',
        path: '/clients',
        icon: 'fas fa-user-friends'
    },
    {
        name: 'Socios',
        path: '/partners',
        icon: 'fas fa-user-tie'
    },
    {
        name: 'Solicitudes',
        path: '/requests',
        icon: 'fas fa-list'
    },
    {
        name: 'Pagos',
        path: '/payments',
        icon: 'fas fa-money-bill'
    },
    {
        name: 'Notificaciones',
        path: '/notifications',
        icon: 'fas fa-comments'
    },
    {
        name: 'Administración',
        icon: 'fas fa-user-shield',
        submenu: [
            {
                name: 'Administrator users',
                path: '/administrators'
            },
            {
                name: 'Checadores',
                path: '/checkers',
            },
        ]
    },
    {
        name: 'Cupones',
        path: '/coupons',
        icon: 'fas fa-wallet'
    },
    {
        name: 'Noticias',
        path: '/news',
        icon: 'fas fa-newspaper'
    }
];

export default Menu;
