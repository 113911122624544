import React from "react"
import { useState } from "react";
import { withNamespaces } from "react-i18next";
import { toast } from "react-toastify";
import Select from "react-select";
import { Card, CardBody, CardHeader, Col, FormGroup, Input, Row, Button } from "reactstrap";
import swal from "sweetalert";
import { createPayment, getClients, getCoupons, getEvents, getFacturationData } from "../../services/Services";
import FormValidator from "../../store/reducers/FormValidator";
import ContentWrapper from "../Layout/ContentWrapper"
import { useEffect } from "react";

const PaymentsForm = props => {

    const [paymentForm, setPaymentForm] = useState({
        inscription: {
            payment_method: '',
            event_id: '',
            user_ids: [],
            coupon_name: '',
            facturation_data_id: ''
        }
    });
    const [metodosPago, setMetodosPago] = useState([
        {
            label: 'Efectivo',
            value: 'Efectivo'
        },
        {
            label: 'Tarjeta de débito',
            value: 'Tarjeta de débito'
        },
        {
            label: 'Tarjeta de crédito',
            value: 'Tarjeta de crédito'
        },
    ])
    const [events, setEvents] = useState([]);
    const [clients, setClients] = useState([]);
    const [cupones, setCupones] = useState([]);
    const [facturationData, setFacturationData] = useState([]);

    useEffect(() => {
        async function getEventsAPI() {
            await getEvents().then((result) => {
                setEvents(formatDataForSelectEdit(result.data));
            });
        }
        async function getClientsAPI() {
            await getClients().then((result) => {
                setClients(formatDataForSelectEdit(result.data));
            });
        }

        async function getCouponsAPI() {
            await getCoupons().then((result) => {
                setCupones(formatDataForSelectEdit(result.data));
            });
        }

        async function getFactDataAPI() {
            await getFacturationData().then((result) => {
                setFacturationData(formatDataForSelectEdit(result.data));
            })
        }

        getEventsAPI();
        getClientsAPI();
        getCouponsAPI();
        getFactDataAPI();
    }, [])

    const formatDataForSelectEdit = (array) => {
        array.forEach((element) => {
            element.value = element.id;
            element.label = element.lastname ? (element.name + ' ' + element.lastname) : element.razon_social ? element.razon_social : element.name;
        });
        return array;
    }

    const handleOnChangeSelect = (value, { action, removedValuem, name }) => {
        switch (action) {
            case "remove-value":
                setPaymentForm({
                    inscription: {
                        ...paymentForm.inscription,
                        [name]: value.value,
                    },
                });
                break;
            case "clear":
                setPaymentForm({
                    inscription: {
                        ...paymentForm.inscription,
                        [name]: [],
                    },
                });
                break;
            default:
                setPaymentForm({
                    inscription: {
                        ...paymentForm.inscription,
                        [name]: value.value,
                    },
                });
                break;
        }
    };

    const handleOnChangeClientSelect = (value, { action, name }) => {
        switch (action) {
            case "remove-value":
                setPaymentForm({
                    inscription: {
                        ...paymentForm.inscription,
                        [name]: [value.value],
                    },
                });
                break;
            case "clear":
                setPaymentForm({
                    inscription: {
                        ...paymentForm.inscription,
                        [name]: [],
                    },
                });
                break;
            default:
                setPaymentForm({
                    inscription: {
                        ...paymentForm.inscription,
                        [name]: [value.value],
                    },
                });
                break;
        }
    }

    const handleOnChangeCuponSelect = (value, { action, name }) => {
        switch (action) {
            case "remove-value":
                setPaymentForm({
                    inscription: {
                        ...paymentForm.inscription,
                        [name]: value.label,
                    },
                });
                break;
            case "clear":
                setPaymentForm({
                    inscription: {
                        ...paymentForm.inscription,
                        [name]: [],
                    },
                });
                break;
            default:
                setPaymentForm({
                    inscription: {
                        ...paymentForm.inscription,
                        [name]: value.label,
                    },
                });
                break;
        }
    }

    //VERIFICA SI HAY ERRORES
    const hasErrors = (inputName, method) => {
        return (
            paymentForm &&
            paymentForm.errors &&
            paymentForm.errors[inputName] &&
            paymentForm.errors[inputName][method]
        );
    };

    const notify = (title) => {
        toast(title, {
            type: "success",
            position: "bottom-center",
        });
    };

    const submitNewPayment = async (e) => {
        e.preventDefault();

        setPaymentForm({
            inscription: {
                ...paymentForm.inscription,
            },
        });

        //Validate if is valid make api request
        await createPayment(paymentForm)
            .then(async () => {
                //USUARIO CREADO CORRECTAMENTE
                notify("Pago creado.");
                setPaymentForm({
                    inscription: {
                        payment_method: '',
                        event_id: '',
                        user_ids: [],
                        coupon_name: '',
                        facturation_data_id: ''
                    }
                });
                props.history.goBack();
            })
            .catch((error) => {
                swal({
                    title: "¡Alerta!",
                    icon: "warning",
                    text: error.data,
                });
            });
    };

    return (
        <ContentWrapper>
            <div className="content-heading">
                <div>
                    Registro de pago
                </div>
            </div>
            <Row>
                <Col xs={12} className="text-center">
                    <Card className="p-3 shadow">
                        <CardHeader className="text-left mb-4">
                            Nuevo pago
                        </CardHeader>
                        <CardBody>
                            <form
                                className="form-horizontal"
                                name="payment"
                                onSubmit={submitNewPayment}
                            >
                                <Row>
                                    <Col xl={12}>
                                        <FormGroup row>
                                            <label className="col-md-4 col-form-label">Método de pago</label>
                                            <div className="col-md-8">
                                                <Select
                                                    onChange={handleOnChangeSelect}
                                                    name="payment_method"
                                                    options={metodosPago}
                                                    invalid={hasErrors("payment_method", "required")}
                                                    data-validate='["required"]'
                                                    value={metodosPago.find((pay_method) => pay_method === paymentForm.inscription.payment_method)}
                                                />
                                                {hasErrors("payment_method", "required") && (
                                                    <span className="invalid-feedback">
                                                        Campo requerido.
                                                    </span>
                                                )}
                                            </div>
                                        </FormGroup>
                                        <FormGroup row>
                                            <label className="col-md-4 col-form-label">
                                                Evento
                                            </label>
                                            <div className="col-md-8">
                                                <Select
                                                    onChange={handleOnChangeSelect}
                                                    name="event_id"
                                                    options={events}
                                                    invalid={hasErrors("event_id", "required")}
                                                    data-validate='["required"]'
                                                    value={events.find((even) => even === paymentForm.inscription.event_id)}
                                                />
                                                {hasErrors("event_id", "required") && (
                                                    <span className="invalid-feedback">
                                                        Campo requerido.
                                                    </span>
                                                )}
                                            </div>
                                        </FormGroup>
                                        <FormGroup row>
                                            <label className="col-md-4 col-form-label">Cliente</label>
                                            <div className="col-md-8">
                                                <Select
                                                    onChange={handleOnChangeClientSelect}
                                                    name="user_ids"
                                                    options={clients}
                                                    invalid={
                                                        hasErrors("user_ids", "required")
                                                    }
                                                    data-validate='["required","user_ids"]'
                                                    value={clients.find((client) => client === paymentForm.inscription.user_ids)}
                                                />
                                                {hasErrors("user_ids", "required") && (
                                                    <span className="invalid-feedback">
                                                        Campo requerido.
                                                    </span>
                                                )}
                                            </div>
                                        </FormGroup>
                                        <FormGroup row>
                                            <label className="col-md-4 col-form-label">Cupón</label>
                                            <div className="col-md-8">
                                                <Select
                                                    onChange={handleOnChangeCuponSelect}
                                                    options={cupones}
                                                    name="coupon_name"
                                                    invalid={
                                                        hasErrors("coupon_name", "required")
                                                    }
                                                    data-validate='["required"]'
                                                    value={cupones.find((cup) => cup === paymentForm.inscription.coupon_name)}
                                                />
                                                {hasErrors("coupon_name", "required") && (
                                                    <span className="invalid-feedback">
                                                        Campo requerido.
                                                    </span>
                                                )}
                                            </div>
                                        </FormGroup>
                                        <FormGroup row>
                                            <label className="col-md-4 col-form-label">Datos de facturación</label>
                                            <div className="col-md-8">
                                                <Select
                                                    onChange={handleOnChangeSelect}
                                                    name="facturation_data_id"
                                                    options={facturationData}
                                                    invalid={hasErrors("facturation_data_id", "required")}
                                                    data-validate='["required"]'
                                                    value={facturationData.find((f_d) => f_d === paymentForm.inscription.facturation_data_id)}
                                                />
                                                {hasErrors("facturation_data_id", "required") && (
                                                    <span className="invalid-feedback">
                                                        Campo requerido.
                                                    </span>
                                                )}
                                            </div>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <hr />
                                <Row>
                                    <Col xl={12} className="d-flex flex-row justify-content-end">
                                        <Button name="boton" type="submit" color="primary" className="shadow">
                                            Guardar
                                        </Button>
                                    </Col>
                                </Row>
                            </form>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </ContentWrapper>
    )
}

export default withNamespaces("translations")(PaymentsForm);