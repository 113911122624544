import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import ReactDataGrid from "react-data-grid";
import { toast } from "react-toastify";
import { Button, Container, Modal } from "reactstrap";
import Swal from "sweetalert";
import { deletePayment, getPayment, getPayments } from "../../services/Services";
import ContentWrapper from "../Layout/ContentWrapper";
import ConfirmPayment from "./ConfirmPayment";
import EditPayment from "./EditPayment";

const Payments = props => {

    const [data, setData] = useState([]);
    const [modal, setModal] = useState(false);
    const [modalEdit, setModalEdit] = useState(false);
    const [payment, setPayment] = useState(null);
    const [paymentEdit, setPaymentEdit] = useState(null);

    useEffect(() => {
        getPaymentsAPI();
    }, []);

    const getPaymentsAPI = async () => {
        await getPayments().then((result) => {
            setData(result.data);
        });
    }

    const notify = (title) => {
        toast(title, {
            type: "success",
            position: "bottom-center",
        });
    };

    const rowGetter = (i) => data[i];

    const confirmButton = props => (
        <div className="text-center py-2">
            <Button color="success" onClick={() => confirmEvent(props)}> <i className="fas fa-check"></i> </Button>
        </div>
    )

    const editButton = props => (
        <div className="text-center py-2">
            <Button color="warning" onClick={() => editEvent(props)}> <i className="far fa-edit"></i> </Button>
        </div>
    )

    const deleteButton = props => (
        <div className="text-center py-2">
            <Button color="danger" onClick={() => deleteEventFunction(props)}> <i class="far fa-trash-alt"></i> </Button>
        </div>
    )

    const deleteEventFunction = async ({ value }) => {
        Swal({
            title: "Deseas eliminar el pago?",
            text: "Una vez eliminado el pago, no se puede recuperar la información.",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then(async (willDelete) => {
                if (willDelete) {
                    await deletePayment(value).then(() => {
                        let payments = [...data];
                        setData(payments.filter((payment) => payment.id !== value));
                        notify("Pago borrado.");
                    }).catch((error) => {
                        Swal(error.data, {
                            icon: "warning",
                        });
                    })
                }
            });
    }

    const confirmEvent = ({ value }) => {
        setPayment(value);
        setModal(!modal);
    }

    const editEvent = async ({ value }) => {
        await getPayment(value).then((result) => {
            setPaymentEdit(result.data);
        })
        setModalEdit(!modalEdit);
    }

    const userFormatter = ({ value }) => {
        return (
            <span>{value.name}</span>
        )
    }

    const currencyFormatter = ({ value }) => {
        return (
            <span>${value}</span>
        )
    }

    const statusFormatter = ({ value }) => {
        return (
            <span>{value ? 'Pagado' : 'Pendiente'}</span>
        )
    }

    const closeModal = () => {
        getPaymentsAPI();
        setPayment(null);
        setModal(!modal);
    }

    const closeModalEdit = () => {
        setPayment(null)
        setModalEdit(!modalEdit);
    }

    const toggle = () => setModal(!modal);
    const toggleEdit = () => setModalEdit(!modalEdit);

    const columns = [
        { key: 'id', name: 'Id pago' },
        { key: 'user', name: 'Nombre', formatter: userFormatter },
        { key: 'name', name: 'Evento' },
        { key: 'price', name: 'Costo', formatter: currencyFormatter },
        { key: 'paid', name: 'Estatus del pago', formatter: statusFormatter },
        { key: 'id', name: 'Confirmar pago', formatter: confirmButton, width: 120 },
        { key: 'id', name: 'Editar pago', formatter: editButton, width: 120 },
        { key: 'id', name: 'Eliminar', formatter: deleteButton, width: 80 }
    ];


    return (
        <ContentWrapper>
            <div className="content-heading">
                <div className="row">
                    <div className="col-md-9">Pagos</div>
                    <div className="text-right col-md-3">
                        <Button color="primary" className="shadow rounded-pill" onClick={() => props.history.push('/payments/new')}> <i class="fas fa-plus"></i> Crear pago</Button>
                    </div>
                </div>
            </div>

            <Container fluid className="shadow">
                <ReactDataGrid
                    columns={columns}
                    rowGetter={rowGetter}
                    rowsCount={data.length}
                    rowHeight={50}
                    minHeight={700} />
            </Container>

            <Modal isOpen={modal} toggle={toggle}>
                <ConfirmPayment id={payment} closeEvent={closeModal} />
            </Modal>

            <Modal isOpen={modalEdit} toggle={toggleEdit}>
                <EditPayment payment={paymentEdit} closeEvent={closeModalEdit} />
            </Modal>
        </ContentWrapper>
    )
}

export default Payments;