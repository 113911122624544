import axios from "axios";

// Produccion
export const ENDPOINT = "https://app.onexposinaloa.com/api/";
export const FILES_ENDPOINT = "https://app.onexposinaloa.com";

// Calidad
// export const ENDPOINT = "http://app.onexposinaloa.com:8080/api/";
// export const FILES_ENDPOINT = "http://app.onexposinaloa.com:8080";

const sessionToken = () => (
    JSON.parse(sessionStorage.getItem("USERSESSION")) ? JSON.parse(sessionStorage.getItem("USERSESSION")).token : false
);


const requestFunction = async (method, url, body) => {
    const headers = sessionToken() ? { 'token': sessionToken(), 'Content-Type': 'application/json' } : { 'Content-Type': 'application/json' }
    const options = {
        method,
        url,
        data: JSON.stringify(body),
        headers
    }

    try {
        const response = await axios(options);

        return { error: false, data: response.data };
    } catch (error) {

        let errorMessages = [];
        const errors = error.response.data;
        for (let error in errors) {
            if (Array.isArray(errors[error])) {
                errorMessages.push(`${errors[error][0]}, `);
            } else {
                errorMessages.push(`${errors[error]}, `);
            }
        }
        throw { error: true, data: "".concat(errorMessages) }
    }
};


export const login = async ({ email, password }) => {
    return await requestFunction('post', `${ENDPOINT}sessions`, { email, password });
}

export const logout = async () => {
    const session = JSON.parse(sessionStorage.getItem("USERSESSION"));
    return await requestFunction('delete', `${ENDPOINT}sessions/${session.token}`);
}

//SPEAKERS
export const newSpeaker = async (speaker) => {
    return await requestFunction('post', `${ENDPOINT}speakers`, speaker)
}

export const getSpeakers = async () => {
    return await requestFunction('get', `${ENDPOINT}speakers`);
}

export const deleteSpeaker = async (id) => {
    return await requestFunction('delete', `${ENDPOINT}speakers/${id}`);
}

export const getSpeaker = async (id) => {
    return await requestFunction('get', `${ENDPOINT}speakers/${id}`);
}

export const updateSpeaker = async (speaker, id) => {
    return await requestFunction('patch', `${ENDPOINT}speakers/${id}`, speaker)
}

//ADMINISTRATORS
export const newAdministrator = async (admin) => {
    return await requestFunction('post', `${ENDPOINT}admins`, admin)
}

export const getAdministrators = async () => {
    return await requestFunction('get', `${ENDPOINT}admins`);
}

export const deleteAdministrator = async (id) => {
    return await requestFunction('delete', `${ENDPOINT}admins/${id}`);
}

export const getAdministrator = async (id) => {
    return await requestFunction('get', `${ENDPOINT}admins/${id}`);
}

export const updateAdministrator = async (admin, id) => {
    return await requestFunction('put', `${ENDPOINT}admins/${id}`, admin)
}

//SPONSORS
export const newSponsor = async (sponsor) => {
    return await requestFunction('post', `${ENDPOINT}sponsors`, sponsor)
}

export const getSponsors = async () => {
    return await requestFunction('get', `${ENDPOINT}sponsors`);
}

export const deleteSponsor = async (id) => {
    return await requestFunction('delete', `${ENDPOINT}sponsors/${id}`);
}

export const getSponsor = async (id) => {
    return await requestFunction('get', `${ENDPOINT}sponsors/${id}`);
}

export const updateSponsor = async (sponsor, id) => {
    return await requestFunction('put', `${ENDPOINT}sponsors/${id}`, sponsor)
}

//EVENTS
export const newEvent = async (event) => {
    return await requestFunction('post', `${ENDPOINT}events`, event)
}

export const getEvents = async () => {
    return await requestFunction('get', `${ENDPOINT}events`);
}

export const deleteEvent = async (id) => {
    return await requestFunction('delete', `${ENDPOINT}events/${id}`);
}

export const notifyEvent = async (id) => {
    return await requestFunction('post', `${ENDPOINT}send_invitations/${id}`)
}

export const getEvent = async (id) => {
    return await requestFunction('get', `${ENDPOINT}events/${id}`);
}

export const updateEvent = async (event, id) => {
    return await requestFunction('put', `${ENDPOINT}events/${id}`, event)
}

export const priceEvent = async (price) => {
    return await requestFunction('post', `${ENDPOINT}special_prices`, price)
}

export const getPrices = async (id) => {
    return await requestFunction('get', `${ENDPOINT}special_prices?event_id=${id}`);
}

export const deletePrice = async (id) => {
    return await requestFunction('delete', `${ENDPOINT}special_prices/${id}`);
}

//EXPOSITIONS
export const newExposition = async (exposition) => {
    return await requestFunction('post', `${ENDPOINT}expositions`, exposition)
}

export const getExpositions = async () => {
    return await requestFunction('get', `${ENDPOINT}expositions`);
}

export const deleteExposition = async (id) => {
    return await requestFunction('delete', `${ENDPOINT}expositions/${id}`);
}

export const getExposition = async (id) => {
    return await requestFunction('get', `${ENDPOINT}expositions/${id}`);
}

export const updateExposition = async (exposition, id) => {
    return await requestFunction('put', `${ENDPOINT}expositions/${id}`, exposition)
}

//EVENT FILES
export const newFile = async (file) => {
    return await requestFunction('post', `${ENDPOINT}event_files`, file)
}

export const getFiles = async () => {
    return await requestFunction('get', `${ENDPOINT}event_files`);
}

export const deleteFile = async (id) => {
    return await requestFunction('delete', `${ENDPOINT}event_files/${id}`);
}

export const getFile = async (id) => {
    return await requestFunction('get', `${ENDPOINT}event_files/${id}`);
}

export const updateFile = async (file, id) => {
    return await requestFunction('put', `${ENDPOINT}event_files/${id}`, file)
}

//INSCRIPTIONS
export const getInscriptions = async () => {
    return await requestFunction('get', `${ENDPOINT}inscriptions`);
}

//CHECKERS
export const newChecker = async (checker) => {
    return await requestFunction('post', `${ENDPOINT}checkers`, checker)
}

export const getCheckers = async () => {
    return await requestFunction('get', `${ENDPOINT}checkers`);
}

export const deleteChecker = async (id) => {
    return await requestFunction('delete', `${ENDPOINT}checkers/${id}`);
}

export const getChecker = async (id) => {
    return await requestFunction('get', `${ENDPOINT}checkers/${id}`);
}

export const updateChecker = async (checker, id) => {
    return await requestFunction('put', `${ENDPOINT}checkers/${id}`, checker)
}

//CLIENTS
export const newClient = async (client) => {
    return await requestFunction('post', `${ENDPOINT}clients`, client)
}

export const getClients = async () => {
    return await requestFunction('get', `${ENDPOINT}clients`);
}

export const deleteClient = async (id) => {
    return await requestFunction('delete', `${ENDPOINT}clients/${id}`);
}

export const getClient = async (id) => {
    return await requestFunction('get', `${ENDPOINT}clients/${id}`);
}

export const updateClient = async (client, id) => {
    return await requestFunction('put', `${ENDPOINT}clients/${id}`, client)
}

//Notifications
export const newNotification = async (notification) => {
    return await requestFunction('post', `${ENDPOINT}notifications`, notification)
}

export const getNotifications = async () => {
    return await requestFunction('get', `${ENDPOINT}notifications`);
}

export const deleteNotification = async (id) => {
    return await requestFunction('delete', `${ENDPOINT}notifications/${id}`);
}

export const getNotification = async (id) => {
    return await requestFunction('get', `${ENDPOINT}notifications/${id}`);
}

export const updateNotification = async (notification, id) => {
    return await requestFunction('put', `${ENDPOINT}notifications/${id}`, notification)
}

//RESOLUTIONS
export const newResolution = async (resolution) => {
    return await requestFunction('post', `${ENDPOINT}resolutions`, resolution)
}

export const getResolutions = async () => {
    return await requestFunction('get', `${ENDPOINT}resolutions`);
}

export const deleteResolution = async (id) => {
    return await requestFunction('delete', `${ENDPOINT}resolutions/${id}`);
}

export const getResolution = async (id) => {
    return await requestFunction('get', `${ENDPOINT}resolutions/${id}`);
}

export const updateResolution = async (resolution, id) => {
    return await requestFunction('put', `${ENDPOINT}resolutions/${id}`, resolution)
}

//ASSISTANCELIST
export const getAssistanceList = async (exposition_id) => {
    return await requestFunction('get', `${ENDPOINT}assistance_list/${exposition_id}`);
}

//CUPONES
export const getCoupons = async () => {
    return await requestFunction('get', `${ENDPOINT}coupons`);
}

export const getCoupon = async (id) => {
    return await requestFunction('get', `${ENDPOINT}coupons/${id}`);
}

export const createCoupon = async (coupon) => {
    return await requestFunction('post', `${ENDPOINT}coupons`, coupon);
}

export const updateCoupon = async (coupon, id) => {
    return await requestFunction('patch', `${ENDPOINT}coupons/${id}`, coupon);
}

export const deleteCoupon = async (id) => {
    return await requestFunction('delete', `${ENDPOINT}coupons/${id}`);
}

// NOTICIAS
export const getNews = async () => {
    return await requestFunction('get', `${ENDPOINT}news`);
}

export const getNew = async (id) => {
    return await requestFunction('get', `${ENDPOINT}news/${id}`);
}

export const createNew = async (news) => {
    return await requestFunction('post', `${ENDPOINT}news`, news);
}

export const updateNew = async (news, id) => {
    return await requestFunction('patch', `${ENDPOINT}news/${id}`, news);
}

export const deleteNew = async (id) => {
    return await requestFunction('delete', `${ENDPOINT}news/${id}`);
}

//SOCIOS
export const getPartners = async () => {
    return await requestFunction('get', `${ENDPOINT}companies`);
}

export const getPartner = async (id) => {
    return await requestFunction('get', `${ENDPOINT}companies/${id}`);
}

export const createPartner = async (company) => {
    return await requestFunction('post', `${ENDPOINT}companies`, company);
}

export const updatePartner = async (company, id) => {
    return await requestFunction('patch', `${ENDPOINT}companies/${id}`, company);
}

export const deletePartner = async (id) => {
    return await requestFunction('delete', `${ENDPOINT}companies/${id}`);
}

// PAYMENTS
export const getPayments = async () => {
    return await requestFunction('get', `${ENDPOINT}inscriptions`);
}

export const getPayment = async (id) => {
    return await requestFunction('get', `${ENDPOINT}inscriptions/${id}`);
}

export const createPayment = async (inscription) => {
    return await requestFunction('post', `${ENDPOINT}inscriptions`, inscription);
}

export const editPayment = async (payment, id) => {
    return await requestFunction('patch', `${ENDPOINT}inscriptions/${id}`, payment);
}

export const deletePayment = async (id) => {
    return await requestFunction('delete', `${ENDPOINT}inscriptions/${id}`);
}

//DATOS FACTURACION
export const getFacturationData = async () => {
    return await requestFunction('get', `${ENDPOINT}facturation_data`);
}

//SOLICITUDE
export const getRequests = async () => {
    return await requestFunction('get', `${ENDPOINT}pendent_partners`)
}

export const updateRequest = async (id, partner) => {
    console.log(partner);
    return await requestFunction('patch', `${ENDPOINT}users/${id}`, partner);
}