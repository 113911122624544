import { ContentState, convertFromHTML, convertToRaw, EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import React, { useState } from 'react';
import { withNamespaces } from 'react-i18next';
import { toast } from 'react-toastify';
import { Button, Card, CardBody, CardHeader, Col, FormGroup, Input, Row } from 'reactstrap';
import Swal from 'sweetalert';
import { createNew, getNew, updateNew } from '../../services/Services';
import FormValidator from '../../store/reducers/FormValidator';
import ContentWrapper from '../Layout/ContentWrapper';
import draftToMarkdown from 'draftjs-to-markdown';
import Showdown from 'showdown';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import ImageCropper from '../Common/ImageCropper';
import { useEffect } from 'react';

const NewsForm = props => {

    const [editMode, setEditMode] = useState(false);
    const [nw, setNw] = useState(null);
    const [editorState, setEditorState] = useState(EditorState.createEmpty())
    const [newForm, setNewForm] = useState({
        new: {
            title: '',
            body: '',
            img: ''
        }
    });

    const submitNewEvent = async (e) => {
        e.preventDefault();

        const form = e.target;
        const inputs = [...form.elements].filter((i) => {
            if (i.name) {
                return ["INPUT", "SELECT"].includes(i.nodeName)
            }
        });
        const { errors } = FormValidator.bulkValidate(inputs);

        setNewForm({
            new: {
                ...newForm.new,
            },
            errors,
        });

        //Validate if is valid make api request
        if (!editMode) {
            await createNew(newForm)
                .then(async (response) => {
                    //EVENTO CREADO CORRECTAMENTE
                    notify("Noticia Creada.");
                    setNewForm({
                        new: {
                            title: '',
                            body: '',
                            img: ''
                        },
                        errors: {},
                    });
                    props.history.goBack();
                })
                .catch((error) => {
                    Swal({
                        title: "¡Alerta!",
                        icon: "warning",
                        text: error.data,
                    });
                });
        } else {
            await updateNew(
                { event: newForm.event },
                nw.id
            ).then(() => {
                //USUARIO MODIFICADO CORRECTAMENTE
                notify("Noticia Actualizada.");
                setNewForm({
                    new: {
                        title: '',
                        body: '',
                        img: ''
                    },
                    errors: {},
                });
                props.history.goBack();
            })
                .catch((error) => {
                    Swal({
                        title: "Alert!",
                        icon: "warning",
                        text: error.data,
                    });
                });
        }
    };

    const validateOnChange = (event) => {
        const input = event.target;
        const value = input.type === "checkbox" ? input.checked : input.value;
        const result = FormValidator.validate(input);

        setNewForm({
            new: {
                ...newForm.new,
                [input.name]: value,
            },
            errors: {
                ...newForm.errors,
                [input.name]: result,
            },
        });
    };

    const hasErrors = (inputName, method) => {
        return (
            newForm &&
            newForm.errors &&
            newForm.errors[inputName] &&
            newForm.errors[inputName][method]
        );
    };

    const notify = (title) => {
        toast(title, {
            type: "success",
            position: "bottom-center",
        });
    };

    const editorStateChange = editorState => {
        const converter = new Showdown.Converter();
        console.log(editorState.getCurrentContent());
        let data = converter.makeHtml(draftToMarkdown(convertToRaw(editorState.getCurrentContent())));
        setNewForm({
            new: {
                ...newForm.new,
                body: data
            }
        });

        setEditorState(editorState);
    }

    const convertToRawFunc = props => {
        const blocksFromHTML = convertFromHTML(props);
        const state = ContentState.createFromBlockArray(
            blocksFromHTML.contentBlocks,
            blocksFromHTML.entityMap,
        );
        // console.log(state);
        setEditorState(EditorState.createWithContent(state));
    }

    const getImage = (img) => {
        setNewForm({
            new: {
                ...newForm.new,
                img: img,
            },
        });
    };

    useEffect(() => {
        async function getNewAPI() {
            await getNew(props.match.params.id)
                .then((result) => {
                    setNw(result.data);
                    setNewForm({
                        new: { ...result.data },
                    });
                    convertToRawFunc(result.data.body);
                })
                .catch((error) => {
                    Swal({
                        title: "Alert!",
                        icon: "warning",
                        text: error.data,
                    });
                });
        }

        if (props.match.params.id) {
            setEditMode(true);
            getNewAPI();
        }
    }, []);

    return (
        <ContentWrapper>
            <div className="content-heading">
                <div>
                    {!editMode ? "Registro de noticia" : "Actualizar Información"}
                </div>
            </div>
            <Row>
                <Col xs={12} className="text-left">
                    <Card className="p-3 shadow">
                        <CardHeader className="text-left mb-4">
                            {!editMode ? "Nueva Noticia" : "Modificar Noticia"}
                        </CardHeader>
                        <CardBody>
                            <form
                                className="form-horizontal"
                                name="event"
                                onSubmit={submitNewEvent}
                            >
                                <Row>
                                    <Col xl={6}>
                                        <FormGroup row>
                                            <label className="col-md-4 col-form-label">Título</label>
                                            <div className="col-md-8">
                                                <Input
                                                    onChange={validateOnChange}
                                                    type="text"
                                                    name="title"
                                                    invalid={hasErrors("title", "required")}
                                                    data-validate='["required"]'
                                                    value={newForm.new.title}
                                                />
                                                {hasErrors("title", "required") && (
                                                    <span className="invalid-feedback">
                                                        Campo requerido.
                                                    </span>
                                                )}
                                            </div>
                                        </FormGroup>
                                        <FormGroup>
                                            <label className='col-md-4 col-form-label'>Cuerpo de la noticia</label>
                                            <div className='col-md-12'>
                                                <Editor
                                                    editorState={editorState}
                                                    wrapperClassName="wysiwig-editor-wrapper"
                                                    editorClassName="form-control"
                                                    editorStyle={{ height: 300 }}
                                                    onEditorStateChange={editorStateChange}
                                                />
                                            </div>
                                        </FormGroup>
                                    </Col>
                                    <Col xl={6}>
                                        <Row>
                                            <Col xl={6}>
                                                <ImageCropper
                                                    imageGetter={getImage}
                                                    id="img"
                                                    type="img"
                                                    user={nw}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>

                                <hr />
                                <Row>
                                    <Col xl={12} className="d-flex flex-row justify-content-end">
                                        <Button type="submit" color="primary" className="shadow">
                                            {!editMode ? "Guardar Noticia" : "Actualizar Noticia"}
                                        </Button>
                                    </Col>
                                </Row>
                            </form>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </ContentWrapper>
    )
}

export default withNamespaces("translations")(NewsForm);