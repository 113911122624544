import React, { useState, useEffect } from 'react';
import ReactDataGrid from 'react-data-grid';
import { Button, Container } from 'reactstrap';
import ContentWrapper from "../Layout/ContentWrapper";
import { FILES_ENDPOINT, getPartners, deletePartner } from '../../services/Services';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from 'sweetalert';

const Partners = (props) => {

    const [data, setData] = useState([]);

    const notify = (title) => {
        toast(title, {
            type: "success",
            position: "bottom-center",
        });
    };

    const ImageFormatter = props => (
        <div className="text-center py-2">
            <img src={`${FILES_ENDPOINT}${props.value}`} className="img-fluid thumb32" alt="avatar" />
        </div>
    );

    const editButton = props => (
        <div className="text-center py-2">
            <Button color="warning" onClick={() => editClient(props)}> <i className="far fa-edit"></i> </Button>
        </div>
    )

    const deleteButton = props => (
        <div className="text-center py-2">
            <Button color="danger" onClick={() => deleteClientFunction(props)}> <i class="far fa-trash-alt"></i> </Button>
        </div>
    )

    const editClient = ({ value }) => {
        props.history.push(`/partners/${value}`)
    }

    const deleteClientFunction = async ({ value }) => {
        Swal({
            title: "Deseas eliminar este socio?",
            text: "Una vez eliminado el socio, no se puede recuperar la información.",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then(async (willDelete) => {
                if (willDelete) {
                    await deletePartner(value).then(() => {
                        let partners = [...data];
                        setData(partners.filter((partner) => partner.id !== value));
                        notify("Socio eliminado.");
                    }).catch((error) => {
                        Swal(error.data, {
                            icon: "warning",
                        });
                    })
                }
            });
    }

    const rowGetter = (i) => data[i]

    const columns = [
        { key: 'avatar', name: 'Avatar', formatter: ImageFormatter, width: 80 },
        { key: 'company', name: 'Compañía' },
        { key: 'name', name: 'Nombre' },
        { key: 'lastname', name: 'Apellido' },
        { key: 'phone_num', name: 'Telefono' },
        { key: 'station_num', name: 'Estación' },
        { key: 'tar', name: 'Permiso' },
        { key: 'state', name: 'Estado' },
        { key: 'city', name: 'Ciudad' },
        { key: 'id', name: 'Editar', formatter: editButton, width: 80 },
        { key: 'id', name: 'Eliminar', formatter: deleteButton, width: 80 }
    ];

    useEffect(() => {
        async function fetchPartnersAPI() {
            await getPartners().then((result) => {
                setData(result.data)
            });
        }

        fetchPartnersAPI();
    }, [])

    return (
        <ContentWrapper>
            <div className="content-heading">
                <div className="row">
                    <div className="col-md-9">Socios</div>
                    <div className="text-right col-md-3">
                        <Button color="primary" className="shadow rounded-pill" onClick={() => props.history.push('/partners/new')}> <i class="fas fa-plus"></i> Crear socio</Button>
                    </div>
                </div>
            </div>

            <Container fluid className="shadow">
                <ReactDataGrid
                    columns={columns}
                    rowGetter={rowGetter}
                    rowsCount={data.length}
                    rowHeight={50}
                    minHeight={700} />
            </Container>
        </ContentWrapper>
    )
}

export default Partners;